(function() {
    angular.module('EntrakV5').controller('landingController', landingController);

    //stateParams.m?
    //stateParams.it?
    //stateParams.rt?
    //stateParams.langCode
    function landingController($scope, $rootScope, $stateParams, $state, Service, IS_LOCAL) {
        console.log('landingController');

        // only for local testing, cannot use cookie
        if (IS_LOCAL && $stateParams.m && $stateParams.it) {
          Service.storageSave("et_method", $stateParams.m);
          Service.storageSave("et_it", $stateParams.it);
          Service.storageSave("et_rt", $stateParams.rt);
          // $rootScope.signInMethod = $stateParams.m;
          // $rootScope.idToken = $stateParams.it;
        }

        var code = Service.toSupportedLangCode($stateParams.langCode);
        if ($rootScope.langCode && $rootScope.langCode === code){
            $state.go(IS_LOCAL ? 'log' : 'dashboard', null, {
                location: 'replace'
            });
        } else {
            $state.transitionTo('landing', {
                langCode: Service.setLangCode(code),
                m: $stateParams.m,
                it: $stateParams.it,
                rt: $stateParams.rt
            }, {
                notify: false
            });
            location.reload();
        }
        
        $scope.$on('$destroy', function() {
            console.log("landingController destroy");
        });

    }
})();
